<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Kategorie bearbeiten">
      <event-category-form
        :loading="loading"
        :category="category"
        :form-submitted="saveCategory"
        mode="edit"
      ></event-category-form>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import EventCategoryForm from "@/components/forms/EventCategoryForm"
import EventCategoriesRepository from "@/repositories/event_categories_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Kategorie bearbeiten"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    EventCategoryForm
  },
  mixins: [ApiErrorHandlerMixin],
  data() {
    return {
      loading: true,
      category: {}
    }
  },
  async created() {
    try {
      this.category = await EventCategoriesRepository.get(this.$route.params.id)
      this.loading = false
    } catch (error) {
      this.loading = false
      this.handleApiError(error)
    }
  },
  methods: {
    async saveCategory(category) {
      await EventCategoriesRepository.update(category.id, category)
      this.$router.push({ name: "EventCategoriesPage" })
    }
  }
}
</script>
